// app.js

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import $ from 'jquery';
import 'what-input';
import 'imagesloaded';
import 'slick-carousel';
import * as Masonry from 'masonry-layout';

import Util from './module/Util';
const util = new Util();

import Menu from './module/Menu';
const menu = new Menu;

var elem = document.querySelector('.DiaryList');
var msnry = new Masonry( elem, {
  itemSelector: '.DiaryListItem',
  columnWidth: '.DiaryListItem',
  percentPosition: true
});

$(function(){

  // common
  $(window).on('scroll', function(){
    if( $(window).scrollTop() > window.innerHeight ){
      if( !$('body').hasClass('top--scrolled') ){
        $('body').addClass('top--scrolled');
      }
    }
    else {
      $('body').removeClass('top--scrolled'); 
    }
  });

  $('a').each(function(){
    $(this).on({
      'touchstart': function(){
        $(this).addClass('hover');
      },
      'touchend': function(){
        $(this).removeClass('hover');
      }
    })
  });


  // home
  if( util.is._bp50 ){
    let areaTop = parseInt(window.innerHeight * (1/10));
    let areaBottom = parseInt(window.innerHeight * (4/7));

    let offsetArr = [];
    $('.ComeOnListItem').each(function(){
      let offsetTop = $(this).offset().top;
      offsetArr.push(offsetTop);
    });

    $(window).on('scroll', function(){
      let lineTop = $(this).scrollTop() + areaTop;
      let lineBottom = $(this).scrollTop() + areaBottom;
    
      for(let i=0; i<offsetArr.length; i++){
        let top = offsetArr[i];

        if( lineTop < top && top < lineBottom ){
          $('.ComeOnListItem').eq(i).find('.ComeOnListText').css({
            height: $('.ComeOnListItem').eq(i).find('.ComeOnListText div').innerHeight()
          });

          $('.ComeOnListItem').eq(i).addClass('show');
        }
        else {
          $('.ComeOnListItem').eq(i).find('.ComeOnListText').css({ height: 0 });
          $('.ComeOnListItem').eq(i).removeClass('show');
        }
      }
    });
  }
  else {
    $('.ComeOnListItem').on('mouseenter', function(){
      $(this).find('.ComeOnListText').css({
        height: $(this).find('.ComeOnListText div').innerHeight()
      });
      $(this).addClass('show');
    });
    $('.ComeOnListItem').on('mouseleave', function(){
      $(this).find('.ComeOnListText').css({ height: 0 });
      $(this).removeClass('show');
    });
  }


  // wysiwyg
  // $('.blocks-gallery-grid').slick({
  //   fade: true,
  //   arrows: false,
  //   dots: true
  // });


  // 
  $('.ArticleList').slick({
    fade: false,
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  })

});


const kanbanTl = gsap.timeline({
  scrollTrigger: {
    trigger: '#ComeOn',
    start: 'bottom bottom'
  }
})

kanbanTl.fromTo(
  ".kanbanmusume .img-1",
  {
    y: 20,
    opacity: 0,
    duration: 200
  },
  {
    y: 0,
    opacity: 1,
    
  }
)
.fromTo(
  ".kanbanmusume .img-2",
  {
    y: 20,
    opacity: 0,
    duration: 200
  },
  {
    y: 0,
    opacity: 1  
  }
);