// Util

'use strict'

export default class {
  constructor(){

    window.onload = function(){
      document.body.classList.add('page--show');
    }

    setTimeout(function(){
      document.body.classList.add('page--show');
    }, 5000);

    this.transitionEndEvents = [
      "webkitTransitionEnd",
      "mozTransitionEnd",
      "oTransitionEnd",
      "transitionend"
    ];
    this.transitionEnd = this.transitionEndEvents.join(" ");

    this.animationEndEvents = [
      "webkitAnimationEnd",
      "mozAnimationEnd",
      "oAnimationEnd",
      "animationend"
    ];
    this.animationEnd = this.animationEndEvents.join(" ");

    this.is = {};

    this.getIs();
    this.scroll();
  }

  getIs(){
    this.is.touchDevice = ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
    this.is.sp_s = window.matchMedia('(max-width:320px)').matches;
    this.is.sp_m = window.matchMedia('(max-width:480px)').matches;
    this.is.sp_l = window.matchMedia('(max-width:640px)').matches;
    this.is.tab_p = window.matchMedia('(max-width:768px)').matches;
    this.is.tab_l = window.matchMedia('(max-width:1040px)').matches;
    this.is.xl = window.matchMedia('(max-width:1280px)').matches;

    this.is.bp20 = window.matchMedia('(min-width:20em)').matches;
    this.is.bp30 = window.matchMedia('(min-width:30em)').matches;
    this.is.bp40 = window.matchMedia('(min-width:40em)').matches;
    this.is.bp50 = window.matchMedia('(min-width:50em)').matches;
    this.is.bp60 = window.matchMedia('(min-width:60em)').matches;
    this.is.bp70 = window.matchMedia('(min-width:70em)').matches;
    this.is._bp20 = window.matchMedia('(max-width:20em)').matches;
    this.is._bp30 = window.matchMedia('(max-width:30em)').matches;
    this.is._bp40 = window.matchMedia('(max-width:40em)').matches;
    this.is._bp50 = window.matchMedia('(max-width:50em)').matches;
    this.is._bp60 = window.matchMedia('(max-width:60em)').matches;
    this.is._bp70 = window.matchMedia('(max-width:70em)').matches;
  }

  scroll(){
    // new SmoothScroll('a[data-smooth]', {
    //   speed: 400,
    //   easing: 'easeInOutQuart',
    //   speedAsDuration: true,
    //   updateURL: false,
    //   header: "[data-smooth-header]"
    // });

    document.addEventListener('scrollStart', _logScrollEvent, false);
    function _logScrollEvent(e){
      if( $(e.detail.anchor).hasClass('js-accordion') && !$(e.detail.anchor).hasClass('open') ){
        $(e.detail.anchor).find('.js-accordion__btn').click();
      }
    }
  }

  random(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /*
   * Usage 
   * util.getCookie('accessed')
   */
  getCookie(key){
    var ck = {};
    var key = key;
    var r = document.cookie.split(';');

    r.forEach(function(value) {
      var content = value.split('=');
      var key = content[0];
      var value = content[1];
      ck[key.trim()] = value;
    });

    if( ck[key] ){
      return ck[key];
    }
    else {
      return false;
    }
  }

  /*
   * Usage 
   * util.setCookie('accessed', 'fish', '/', '', '', '');
   */
  setCookie(key, value, path, domain, maxAge, expires){
    var ckstr = "";

    if( key && value ){
      ckstr += key+"="+encodeURIComponent(value);
    }

    if( path ){
      ckstr += "; path="+path;
    }

    if( domain ){
      ckstr += "; domain='"+domain+"'";
    }

    if( maxAge ){
      ckstr += "; max-age="+maxAge;
    }

    if( expires ){
      // expires = new Date(expires);
      ckstr += "; expires="+expires;
    }

    document.cookie = ckstr;
  }

}